import React from 'react';
import Head from 'next/head';
import {GoogleFonts} from 'next-google-fonts';
import {v4 as uuidV4} from 'uuid';
import {ApolloProvider} from '@apollo/client';
import '@hy-vee/design-tokens/src/tokens.css';
import '@hy-vee/design-system/lib/design-system.css';
import '@hy-vee/global-navigation/lib/cjs/index.css';
import '@hy-vee/react-web-and-mobile-ui-components/whammy.css';
import './app.css';
import {initialize as monitoringInitialize} from '@hy-vee/monitoring';

import {useApollo} from '../graphql/use-apollo';
import {CustomerProvider} from '../contexts/customer-context';
import PageLayout from '../components/page-layout';
import HeaderPage from '../components/header-page';
import {googleTagManagerScriptNonce} from '../views/authentication/google-tag-manager-wrapper';
import {logWebVitals} from '../utils/log-web-vitals';
import ModalProvider from '../components/modals/modal-provider';
import FeatureToggleProvider from '../contexts/feature-toggle/provider';
import {CookieProvider} from '../contexts/cookie-context';

const MyApp = (props: any) => {
    const {Component, appProps, ...pageProps} = props;
    const apolloClient = useApollo(pageProps);

    React.useEffect(() => {
        monitoringInitialize();
    }, []);

    return (
        <CookieProvider>
            <CustomerProvider pageComponent={Component}>
                <Head>
                    <title>{'Hy-Vee Deals – One-Stop Savings'}</title>
                    {googleTagManagerScriptNonce(appProps.nonce)}
                </Head>
                <GoogleFonts href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap" />
                <PageLayout>
                    <ApolloProvider client={apolloClient}>
                        <FeatureToggleProvider>
                            <ModalProvider forceStoreSelection={Component.forceStoreSelection}>
                                <HeaderPage>
                                    <Component {...pageProps} />
                                </HeaderPage>
                            </ModalProvider>
                        </FeatureToggleProvider>
                    </ApolloProvider>
                </PageLayout>
            </CustomerProvider>
        </CookieProvider>
    );
};

MyApp.getInitialProps = async ({
    Component,
    ctx
}: any) => {
    const nonce = uuidV4();

    let componentProps = {};

    if (Component.getInitialProps) {
        componentProps = await Component.getInitialProps(ctx);
    }

    return {
        ...componentProps,
        appProps: {
            nonce
        }
    };
};

export const reportWebVitals = logWebVitals;

export default MyApp;
